export const environment = {
    production: false,
    account: 'https://supplier-connect-account-34bfbmshvq-el.a.run.app/',
    invoice: 'https://supplier-connect-invoice-34bfbmshvq-el.a.run.app/',
    baseUrl: 'https://supplier-connect-account-34bfbmshvq-el.a.run.app/',
    dashboardUrl: 'https://supplier-connect-dashboard-34bfbmshvq-el.a.run.app/',
    handshakeServiceUrl: 'https://supplier-connect-handshake-34bfbmshvq-el.a.run.app/',
    customerUrl: 'https://supplier-connect-customer-34bfbmshvq-el.a.run.app/',
    purchaseOrderUrl: 'https://supplier-connect-purchase-order-34bfbmshvq-el.a.run.app/',
    CAM_API_URL: 'https://dev.taxgenie.online/cam/api/v1/',
    UI_BASE_URL: "https://dev.supplier.payinvoice.online",
    ENVIRONMENT: "supplier-connect",
    APIGEE_MANAGEMENT_SERVICE_BASE_URL: 'https://apigee-account-service-34bfbmshvq-uc.a.run.app/',
    CAM_URI: "cam/api/v1",
    MDM_API_URL: "https://gst.taxgenie.online/mdm-master/v1/",
    HANDSHAKE_URL:"https://dev.taxgenie.online/handshake/api/v1",
    API4BUSINESS_ENVIRONMENT: "dev",
    API4BUSINESS_URL:"https://dev.api.api4business.com/",
    API4BUSINESS_PRODUCTS: [
        "OAuth",
        "test-oauth",
        "PassThroughAPIDevs",
        "get-einvoice-details-from-govt-v1",
        "gst-filing-details"
    ]
};
