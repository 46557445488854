import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, throwError, timer } from 'rxjs';
import { takeUntil } from 'rxjs';
import { API_URL } from 'src/app/constants/API/api-url';
import { IEInvoiceApplicableResp, IEInvoiceSupplierDbResp, IInvoiceDataRequest, IInvoiceDataResponse, IInvoiceSingleHistoryDataResponse, IInvoiceSyncHistoryDataRequest, IInvoiceSyncHistoryDataResponse, ISyncInvoiceBaseErrorResp, ISyncInvoiceDataRequest, IUpdateInvoiceApplicableStatusRequest, IFilingStatusRequest, IFilingStatusResponse, IBuyerContactPersonBaseResponse, } from '../../models/invoice-credit-notes/invoice.model';
import { IInvoiceProcessRequestBody, IInvoiceProcessResponse, IInvoiceProcessResponseBody, IInvoiceUploadResponse, ISupplierBuyerResponse, IDocumentDataResponse } from '../../models/invoice-credit-notes/invoice-upload.model';
import { BASE_URL } from 'src/app/constants/API/microservice-base-url';
import { IGenericLookUpResponse } from '../../models/base/lookup-base.model';
import { IInvoiceCommonRes, IInvoiceHeaderDetailsRes, IInvoiceHeaderDetailsResBody, IInvoiceUniqueRes, ISupportingDocumentCategory } from '../../models/invoice-credit-notes/invoice-header-details.model';
import { IInvoiceLineItems, lineItemsResponseBody } from '../../models/invoice-credit-notes/invoice-line-items.model';
import { environment } from 'src/environments/environment.development';
import { ToastrService } from 'ngx-toastr';
import { GstVerificationService } from './gst-verification.service';
import { ISupplierBuyerHandShakeRequest } from '../../models/handshake/supplierToBuyerHandshake';
import { ENV_VARIABLES } from 'src/app/constants/API/env-variables';
import { UUIDGenerator } from '../../utils/UUIDGenerator';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {
    constructor(private gstVerificationService: GstVerificationService) { }
    private httpClient: HttpClient = inject(HttpClient);
    private toast: ToastrService = inject(ToastrService);
    private gstinVerificationService = inject(GstVerificationService);
    private jwtToken: string = localStorage.getItem('jwtToken') as string;

    getPurchaseOrderFromCustomer(id: number): Observable<IGenericLookUpResponse> {
        return this.httpClient.get<IGenericLookUpResponse>(`${environment.invoice}${API_URL.invoice.getPO}` + '/' + id);
    }
    getAllCustomersForSupplier(id: number): Observable<IGenericLookUpResponse> {
        return this.httpClient.get<IGenericLookUpResponse>(`${environment.invoice}${API_URL.invoice.getAllCustomers}` + '/' + id);
    }
    uploadInvoice(request: FormData): Observable<IInvoiceUploadResponse> {
        return this.httpClient.post<IInvoiceUploadResponse>(`${environment.invoice}${API_URL.invoice.invoiceUpload}`, request);
    }
    getDocumentType(): Observable<IGenericLookUpResponse> {
        return this.httpClient.get<IGenericLookUpResponse>(`${environment.invoice}${API_URL.invoice.getDocumentType}`);
    }
    getBuyerSupplier(supplierId: number, buyerId: number): Observable<ISupplierBuyerResponse> {
        return this.httpClient.get<ISupplierBuyerResponse>(`${environment.invoice}${API_URL.invoice.getCompanyDetails}` + '/' + supplierId + '/' + buyerId);
    }
    getInvoiceFiles(id: number): Observable<IDocumentDataResponse> {
        return this.httpClient.get<IDocumentDataResponse>(`${environment.invoice}${API_URL.invoice.getInvoiceAndSupportingDocuments}` + '/' + id);
    }
    saveInvoiceDetails(request: IInvoiceProcessRequestBody): Observable<IInvoiceProcessResponseBody> {
        return this.httpClient.post<IInvoiceProcessResponseBody>(`${environment.invoice}${API_URL.invoice.invoiceDetails}`, request);
    }
    getInvoiceDetails(id: number): Observable<IInvoiceProcessResponse> {
        return this.httpClient.get<IInvoiceProcessResponse>(`${environment.invoice}${API_URL.invoice.invoiceDetails}` + '/' + id);
    }
    getInvoiceTableData(request: IInvoiceDataRequest): Observable<IInvoiceDataResponse> {
        return this.httpClient.post<IInvoiceDataResponse>(`${environment.invoice}${API_URL.invoice.getAllInvoices}`, request);
    }
    getHeaderDetails(id: number, supplierId: string): Observable<IInvoiceHeaderDetailsRes> {
        return this.httpClient.get<IInvoiceHeaderDetailsRes>(`${environment.invoice}${API_URL.invoice.getHeaderDetails}` + '/' + id + '/' + supplierId);
    }
    getStates(): Observable<IGenericLookUpResponse> {
        return this.httpClient.get<IGenericLookUpResponse>(`${environment.invoice}${API_URL.common.getStates}`);
    }
    getCountries(): Observable<IGenericLookUpResponse> {
        return this.httpClient.get<IGenericLookUpResponse>(`${environment.invoice}${API_URL.common.getCountries}`);
    }
    getPort(): Observable<IGenericLookUpResponse> {
        return this.httpClient.get<IGenericLookUpResponse>(`${environment.invoice}${API_URL.invoice.getPort}`);
    }
    saveHeaderDetails(request: IInvoiceHeaderDetailsResBody,userId:string): Observable<IInvoiceCommonRes> {
        return this.httpClient.post<IInvoiceCommonRes>(`${environment.invoice}${API_URL.invoice.getHeaderDetails}${"?userId="}${userId}`, request);
    }
    getLineItems(id: number): Observable<IInvoiceLineItems> {
        return this.httpClient.get<IInvoiceLineItems>(`${environment.invoice}${API_URL.invoice.getLineItems}` + '/' + id);
    }
    addLineItems(request: lineItemsResponseBody): Observable<IInvoiceCommonRes> {
        return this.httpClient.post<IInvoiceCommonRes>(`${environment.invoice}${API_URL.invoice.addLineItems}`, request);
    }
    deleteLineItems(id: number): Observable<IInvoiceCommonRes> {
        return this.httpClient.delete<IInvoiceCommonRes>(`${environment.invoice}${API_URL.invoice.deleteLineItem}` + '/' + id);
    }
    syncInvoiceDataFromGov(request: ISyncInvoiceDataRequest, gstin: string | null): Observable<any> {
        let headers = new HttpHeaders();

        // Set the GSTIN header if it's not null
        if (gstin !== null) {
            headers = headers.set('gstin', gstin);
            headers = headers.set('uuid', UUIDGenerator.generateUUID());
        }
        return this.httpClient.get<any>(`${environment.invoice}${API_URL.invoice.syncInvoiceDataFromGov}?rtnprd=${request.rtnprd}&supplyType=${request.supplyType}&supplierId=${request.supplierId}&companyMasterId=${request.companyMasterId}&userEmail=${request.userEmail}&referenceId=${request.referenceId}`, { headers: headers });
    }

    // Get E-Invoice Applicable status from Supplier DB
    getEInvoiceApplicableStatusDB(suppCompId: string | null): Observable<IEInvoiceSupplierDbResp> {
        return this.httpClient.get<IEInvoiceSupplierDbResp>(`${environment.customerUrl}${API_URL.invoice.eInvoiceApplicabilityFromDB}?suppCompId=${suppCompId}`);
    }

    // Get E-invoice applicable status API from Government
    getEInvoiceApplicableStatusGov(gstin: string): Observable<IEInvoiceApplicableResp> {
        return this.httpClient.get<IEInvoiceApplicableResp>(`${environment.API4BUSINESS_URL}${API_URL.invoice.eInvoiceApplicabilityFromGov}?gstin=${gstin}`);
    }

    updateEInvoiceApplicableStatusDB(request: IUpdateInvoiceApplicableStatusRequest): Observable<IEInvoiceSupplierDbResp> {
        return this.httpClient.put<IEInvoiceSupplierDbResp>(`${environment.customerUrl}${API_URL.invoice.updateEInvoiceApplicabilityFromDB}`, request);
    }
    checkUniqueInvoiceNumber(invoiceNumber: string, supplierId: number): Observable<IInvoiceUniqueRes> {
        return this.httpClient.get<IInvoiceUniqueRes>(`${environment.invoice}${API_URL.invoice.checkUniqueInvoiceNumber}?invoiceNumber=${invoiceNumber}&supplierId=${supplierId}`);
    }
    getEInvoiceByInvoiceId(invoiceId: number, gstin: string | null,userEmail:string) {
        const headers = new HttpHeaders({
            gstin: gstin != null ? gstin : '',
            userEmail:userEmail!=null? userEmail:''
        })
        return this.httpClient.get<any>(`${environment.invoice}${API_URL.invoice.getEinvoiceByInvoiceId}${invoiceId}`, { headers: headers });
    }
    getSupportingDocumentCategory(): Observable<ISupportingDocumentCategory> {
        return this.httpClient.get<any>(`${environment.invoice}${API_URL.invoice.getDocumentCategory}`);
    }
    postSupportingDocument(formData: FormData, id: number): Observable<IInvoiceCommonRes> {
        return this.httpClient.post<IInvoiceCommonRes>(`${environment.invoice}${API_URL.invoice.postSupportingDocument}` + '/' + id, formData);
    }

    getAndUpdateEInvoiceStatusFromGov(gstin: string | null, suppCompId: string | null | undefined, callBackFunction: Function | null, errorMsg: string): Observable<any> {
        return new Observable(observer => {
            if (gstin) {
                // Generate OAuth token
                this.getEInvoiceApplicableStatusGov(gstin)
                    .subscribe((resp: IEInvoiceApplicableResp) => {
                        if (resp?.body?.einvStatus == "Yes" && resp?.body?.irnStatus == "Generated") {
                            this.toast.success("E-Invoice status updated successfully");
                        } else {
                            this.toast.info("You are not E-Invoice Applicable");
                            observer.error("Not E-Invoice Applicable");
                        }
                        // if status is updated then update in supplier DB
                        const updateEInvoiceStatusReq: IUpdateInvoiceApplicableStatusRequest = {
                            suppCompId: suppCompId,
                            genEInvoice: resp?.body?.irnStatus == "Generated",
                            eInvoiceApplicable: resp?.body?.einvStatus == "Yes"
                        };
                        this.updateEInvoiceApplicableStatusDB(updateEInvoiceStatusReq)
                            .subscribe((resp: IEInvoiceSupplierDbResp) => {
                                // this.toast.success("E-Invoice status updated successfully");
                            },
                                (error) => {
                                    // this.toast.error("Cannot set E-Invoice Status");
                                });
                        observer.next(resp); // Emit value to subscribers
                        observer.complete(); // Complete the observable
                    },
                        (error: ISyncInvoiceBaseErrorResp) => {
                            const errorResp = error?.error?.errorMessage;
                            if (errorResp == 'Invalid Access Token' || errorResp == "Access Token expired" || errorResp == "Invalid Credentials") {
                                // generate a new auth token
                                this.gstinVerificationService.generateOauthTokenForApi4Business()
                                    .subscribe((resp) => {
                                        //   this.gstVerificationAccessToken = resp.access_token;
                                        localStorage.setItem("api4BusinessToken", resp.access_token);
                                        // this.toast.success("OAuth token generated");

                                        if (callBackFunction)
                                            this.getAndUpdateEInvoiceStatusFromGov(gstin, suppCompId, null, errorMsg).subscribe();
                                    },
                                        (error) => {
                                            this.toast.error("Cannot generate OAuth Token! Please try again later");
                                            observer.error(error); // Emit error to subscribers
                                        });
                            }
                            else {
                                this.toast.error(errorMsg);
                                observer.error(errorMsg); // Emit error to subscribers
                            }
                        });
            }
        });
    }

    deleteSupportingDocument(id: number): Observable<IInvoiceCommonRes> {
        return this.httpClient.delete<IInvoiceCommonRes>(`${environment.invoice}${API_URL.invoice.deleteSupportingDocument}` + '/' + id);
    }
    sendInvoiceToBuyerViaEmail(request: ISupplierBuyerHandShakeRequest) {
        return this.httpClient.post(`${environment.invoice}${API_URL.invoice.sendInvEmailToBuyer}`, request);
    }

    getSyncInvoiceDataProgressStatus(gstin: string): Observable<Map<string, boolean>> {
        return this.httpClient.get<Map<string, boolean>>(`${environment.invoice}${API_URL.invoice.getSyncInvoiceProgressStatus}?gstin=${gstin}`);
    }

    getInvoiceSyncHistoryData(request: IInvoiceSyncHistoryDataRequest) {
        return this.httpClient.post<IInvoiceSyncHistoryDataResponse>(`${environment.invoice}${API_URL.invoice.getSyncInvoiceHistoryData}`, request);
    }
    getInvoiceUploadHistoryData(request: IInvoiceSyncHistoryDataRequest) {
        return this.httpClient.post<IInvoiceSyncHistoryDataResponse>(`${environment.invoice}${API_URL.invoice.getSyncInvoiceHistoryData}`, request);
    }
    getInvoiceHistorySingleRecord(id: number): Observable<IInvoiceSingleHistoryDataResponse> {
        return this.httpClient.get<IInvoiceSingleHistoryDataResponse>(`${environment.invoice}${API_URL.invoice.getInvoiceHistorySingleRecord}` + '/' + id);
    }

    getFilingStatus(request: IFilingStatusRequest): Observable<IFilingStatusResponse> {
        return this.httpClient.post<IFilingStatusResponse>(`${environment.invoice}${API_URL.invoice.getFilingStatus}`, request);
    }

    getBuyerContactPersonList(gstin: string | null, pan: string | null): Observable<IBuyerContactPersonBaseResponse> {
        let appendString = gstin != null ? '?gstin=' + gstin : '?pan=' + pan;
        return this.httpClient.get<IBuyerContactPersonBaseResponse>(`${environment.invoice}${API_URL.invoice.getBuyerContactPersonList}${appendString}`);
    }
}